/*
Google Ad Utils
DFP (DoubleClick for Publishers): client side solution
DAI (Dynamic Ad Insertion): stream embed ads

External Dependency:
window.ENABLE_ADPARAMS_SERVICE
window.g_mvpdsMap
window.userType
window.isTVE
window.isMVPDLoggedIn
window.CHANNEL_AD_URL
window.VOD_AD_URL
window.DFP_AOL_BUNDLEID
window.DFP_CONFIG
window.DFP_ID
window.DFP_AGENT[device]
window.DFP_NAME
window.DFP_CONFIG_SERVER
 */

let _dfpDataCache = {};

/**
 * Vod DFP process
 * @param video
 * @param device
 * @param callback
 * @returns {string}
 */
function getVodDfpAdTag(video, device, callback) {
	var dfpData = _getDfpData(device);
	var dfpUserType = dfpData[0];
	var dfpParams = dfpData[1];
	dfpParams.vid = video.extId;
	var adParams = {
		id: window.DFP_ID,
		agent: window.DFP_AGENT[device],
		name: window.DFP_NAME,
		userType: dfpUserType
	};
	if (window.ENABLE_ADPARAMS_SERVICE) {
		const method = 'getVodAdParams';
		const videoData = {'programId': video.id};
		if (typeof callback == 'function') {
			_getExtAdParams(method, videoData, function (extAdParams) {
				callback(_handleVodDfpExtParams(dfpParams, adParams, extAdParams));
				if (!_dfpDataCache[method]) {
					_dfpDataCache[method] = {};
				}
				_dfpDataCache[method][JSON.stringify(video.extId)] = extAdParams;
			});
		} else {
			console.debug('No callback, use cached adparams');
			let cachedExtAdParams = _dfpDataCache[method] && _dfpDataCache[method][JSON.stringify(video.extId)];
			if (cachedExtAdParams) {
				return _handleVodDfpExtParams(dfpParams, adParams, cachedExtAdParams);
			}
		}
	} else {
		if (typeof callback == 'function') {
			callback(_handleVodDfpExtParams(dfpParams, adParams));
		} else {
			return _handleVodDfpExtParams(dfpParams, adParams);
		}
	}
}

/**
 *
 * @param video
 * @param device
 * @returns {Promise<unknown>}
 */
function fetchVodDfpAdTag(video, device) {
	return new Promise(resolve => {
		getVodDfpAdTag(video, device, resolve)
	});
}

function _handleVodDfpExtParams(dfpParams, adParams, extAdParams) {
	if (extAdParams && extAdParams.iu) {
		$.extend(adParams, extAdParams.iu);
	}
	if (extAdParams && extAdParams.cust_params) {
		$.extend(dfpParams.customParams, extAdParams.cust_params);
	}
	dfpParams.cust_params = $.param(dfpParams.customParams);
	delete dfpParams.customParams;
	dfpParams.iu = _processAdParams(window.VOD_AD_URL, adParams);
	return window.DFP_CONFIG_SERVER + $.param(dfpParams)
}

/**
 * channel DFP process
 * @param epgProgram
 * @param device
 * @param callback
 * @returns {{cust_params: *, iu: *}}
 */
function getChannelDfpParams(epgProgram, device, callback) {
	var dfpData = _getDfpData(device);
	var dfpUserType = dfpData[0];
	var dfpParams = dfpData[1];
	// use the 2nd var in grouping value as network ID: "livetv:univision:univisioneast" -> univision
	// if not exists, use seo-name in lowercase
	var network = epgProgram.grouping.split(':')[1] ? epgProgram.grouping.split(':')[1] : epgProgram.seoName.toLowerCase();
	if (epgProgram.t)
		dfpParams.customParams.type = epgProgram.t.toLowerCase();
	if (epgProgram.seoName)
		dfpParams.customParams.channel = epgProgram.seoName.toLowerCase();
	if (epgProgram.e)
		dfpParams.customParams.program = epgProgram.e.toLowerCase();
	var adParams = {
		id: window.DFP_ID,
		agent: window.DFP_AGENT[device],
		name: window.DFP_NAME,
		userType: dfpUserType,
		network: network
	};

	// UNI-6699
	if (epgProgram.extraInfo) {
		// Sample: 'seriesId=612312&genre=uniny,unide,anygenre'
		var extraInfo = JSON.parse('{"' + decodeURI(epgProgram.extraInfo).replace(/"/g, '\\"')
				.replace(/&/g, '","').replace(/=/g, '":"') + '"}');
		if (extraInfo.genre) {
			dfpParams.customParams.genre = extraInfo.genre;
		}
		if (extraInfo.seriesId) {
			dfpParams.customParams.seriesId = extraInfo.seriesId;
		}
	}

	if (window.ENABLE_ADPARAMS_SERVICE) {
		const method = 'getLiveChannelAdParams';
		const channelData = {
			'channel': epgProgram.seoName,
			'start': epgProgram.sl
		};
		if (typeof callback == 'function') {
			_getExtAdParams(method, channelData, function (extAdParams) {
				callback(_handleChannelDfpExtParams(dfpParams.customParams, device, adParams, extAdParams));
				if (!_dfpDataCache[method]) {
					_dfpDataCache[method] = {};
				}
				_dfpDataCache[method][JSON.stringify(epgProgram.seoName)] = extAdParams;
			});
		} else {
			console.debug('No callback, use cached adparams');
			let cachedExtAdParams = _dfpDataCache[method] && _dfpDataCache[method][JSON.stringify(epgProgram.seoName)];
			if (cachedExtAdParams) {
				return _handleChannelDfpExtParams(dfpParams.customParams, device, adParams, cachedExtAdParams);
			}
		}
	} else {
		if (typeof callback == 'function') {
			callback(_handleChannelDfpExtParams(dfpParams.customParams, device, adParams));
		} else {
			return _handleChannelDfpExtParams(dfpParams.customParams, device, adParams);
		}
	}
}

function fetchChannelDfpParams(epgProgram, device) {
	return new Promise(resolve => {
		getChannelDfpParams(epgProgram, device, resolve)
	});
}

function _handleChannelDfpExtParams(customParams, device, adParams, extAdParams) {
	if (extAdParams && extAdParams.iu) {
		$.extend(adParams, extAdParams.iu);
	}
	if (extAdParams && extAdParams.cust_params) {
		$.extend(customParams, extAdParams.cust_params);
	}
	var adUnit = _processAdParams(window.CHANNEL_AD_URL, adParams);

	// ChromeCast: pass the object to the receiver
	// Web: Encoded into String to pass in the URL
	if (device !== 'chromecast') {
		customParams = encodeURIComponent($.param(customParams));
	}

	return {
		iu: adUnit,
		cust_params: customParams,
		rdp: window.DFP_CONFIG.rdp
	}
}

/**
 * Process DFP adtags or params
 * common dependency: isTVE, authorizedResourceIds, isMVPDLoggedIn, userType,
 * 				DFP_CONFIG_SERVER, DFP_ID, DFP_AGENT, DFP_NAME, DFP_CONFIG, DFP_AOL_BUNDLEID
 * @param device
 * @returns {string|{cust_params: *, iu: *}}
 */
function _getDfpData(device) {
	var params = $.extend({}, window.DFP_CONFIG);
	var userType;
	var userTypeForDFP = window.userType;
	if (window.isTVE) {
		if (window.authorizedResourceIds !== '')
			userTypeForDFP = 'mvpdsubscriber';
		else
			userTypeForDFP = 'mvpdpreview';
	}
	switch (userTypeForDFP) {
			// TODO: check isMVPDLoggedIn
			// TODO: check currentVideo|nlLastProgram.noAccess
		case 'anonymous':
		case 'member':
			userType = 'preview';
			break;
		case 'subscriber':
		case 'trialsubscriber':
		case 'vipuser':
		case 'sub_and_mvpd':
		case 'trail_and_mvpd':
			userType = 'subscriber';
			break;
		case 'mvpdsubscriber':
		case 'reg_and_mvpd':
			userType = 'mvpd';
			break;
		case 'mvpdpreview':
			userType = 'preview';
			break;
		default:
			userType = '';
	}
	// DFP SITE TYPE
	params.customParams = {};
	params.customParams.appbundle = window.DFP_AOL_BUNDLEID[device];
	// Custom parameter to identify MVPD
	if (window.isTVE && window.isMVPDLoggedIn) {
		params.customParams.mvpd = window.g_mvpdsMap[mvpdProvider].url;
	}
	if (device === 'desktop' || device === 'mobileweb') {
		params.customParams.pw = $('#nlplayerhtml5').width();
		params.customParams.ph = $('#nlplayerhtml5').height();
	}
	params.description_url = window.top.location.href;

	return [userType, params];
}

/**
 * replace placeholder attributes with data
 * @param template
 * @param adParams
 * @returns {string}
 */
function _processAdParams(template, adParams) {
	return Object.keys(adParams).reduce(function (a, b) {
		return a.replace('${' + b + '}', adParams[b]);
	}, template);
}

/**
 * Get additional ad params from adParams service.
 * Instruction see: https://confluence.neulion.com/display/NDP/Univision+DFP+Custom+Parameters
 * API see: https://jira.neulion.com/browse/NDP-3847 Add AdParams base support in platform
 * @param method
 * @param data
 * @param callback
 */
function _getExtAdParams(method, data, callback) {
	if (typeof callback == 'function') {
		$.ajax({
			url: '/service/adparams?format=json',
			type: 'POST',
			data: {
				method,
				...data
			},
			dataType: 'json',
			success: function (result) {
				callback(result);
			},
			error: function (jqXHR) {
				console.log('AdParams service error, code: ' + (jqXHR && jqXHR.status));
				callback({});
			}
		});
	} else {
		console.error('Error: callback not defined!');
	}
}
